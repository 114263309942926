import { useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import employers from '../data/employers.json';

export const Experience = () => {
    const [jobs, setJobs] = useState(0);

    return (
        <section>
            <Container>
                <div className="experience" id="experience">
                    <h1
                        data-aos-delay="2000"
                        data-aos="fade-up"
                        data-aos-once="true"
                        id="Experience"
                        className="title"
                    >
                        Experience
                    </h1>
                </div>
                <div>
                    <section data-aos="fade-up" data-aos-once="true">
                        <Row>
                            <Col md={6} lg={4} xl={3}>
                                <div className="btn-container">
                                    {employers &&
                                        employers.map((item, i) => (
                                            <button
                                                key={item.id}
                                                onClick={() => setJobs(i)}
                                                variant={i === jobs ? "primary" : "secondary"}
                                                className={`job-btn ${i === jobs ? "active-btn active" : ""}`}
                                            >
                                                <span className="employer">{item.employer}</span>
                                            </button>
                                        ))}
                                </div>
                            </Col>
                            <Col md={6} lg={8} xl={9}>
                                <article className="job-info">
                                    <h3>{employers[jobs].role}</h3>
                                    <p className="job-date">{employers[jobs].period}</p>
                                    {employers[jobs].workDone.map((item, i) => (
                                        <div key={i} className="job-desc">
                                            <FaAngleDoubleRight className="job-icon" />
                                            <p>{item}</p>
                                        </div>
                                    ))}
                                </article>
                            </Col>
                        </Row>
                    </section>
                </div>
            </Container>
        </section>
    );
};

