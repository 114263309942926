import { Col, Container, Row } from "react-bootstrap"
import comp from '../assets/img/computer-retro.webp';
import { Linkedin} from "react-bootstrap-icons";
import { Github } from "react-bootstrap-icons";
import { FilePerson } from "react-bootstrap-icons";
import resume from '../assets/docs/RobertHardial_New1Resume 1.pdf';

export const Connect = () => {
    return(
        <section className="connect" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col md={6} className="text-center mb-4 mb-md-0">
                    <img src={comp} alt="Contact Us"/>
                    </Col>
                    <Col>
                    <h2>Let's Connect</h2>
                    <div className="d-flex justify-content-center justify-content-md-start icons">
                        <a href="https://www.linkedin.com/in/robert-hardial-padilla-" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: "white" }}>
                        <Linkedin className="linkedin"></Linkedin>
                        </a>
                        <a href="https://www.github.com/robhardial?tab=repositories" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: "white" }}>
                        <Github className="github"></Github>
                        </a>
                        <a href = {resume} download = "RHPResume.pdf" style={{ textDecoration: 'none', color: "white"}} >
                        <FilePerson className="resume"></FilePerson>
                        </a>
                    </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}