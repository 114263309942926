import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {ArrowRightCircle} from "react-bootstrap-icons";
import profpic from "../assets/img/profpic.png";


export const Banner = () => {

    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const [index, setIndex] = useState(1);
    const toRotate = [ "Full Stack Engineer", "Cloud Developer" ];
    const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setIndex(prevIndex => prevIndex - 1);
        setDelta(period);
    } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setIndex(1);
        setDelta(250);
    } else {
        setIndex(prevIndex => prevIndex + 1);
    }
  }
    



    return(
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7} className="text-column">
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1>{`Hi my name is Robert and I'm a`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Full Stack Engineer", "Cloud Developer" ]'><span className="wrap">{text}</span></span></h1>
                        <p>Hello, my name is Robert Hardial Padilla and I'm from Long Island, New York. I am a passionate Full Stack Engineer who thrives on solving challenges and exploring a diverse range of topics.</p>
                        <a href="#connect"><button onClick={() => console.log('connect')}>Lets Connect<ArrowRightCircle size={25} /></button></a>
                    </Col>
                        <Col xs={12} md={6} xl={5}>
                            <img src={profpic} alt="Header Img"/>
                        </Col>
                </Row>
            </Container>
        </section>
    )
}