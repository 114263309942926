export const Footer = () => {
        return (
            <footer className="footer-container">
              <a
                href="https://github.com/robhardial/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <p className="content">Designed and Built by Robert Hardial-Padilla</p>
              </a>
            </footer>
        )
}