import { Col, Container, Row } from "react-bootstrap";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import cloud from '../assets/img/aws.png';
import grazi from '../assets/img/grazi.png';
import travlr from '../assets/img/travlr-getaway.jpeg';
import android from '../assets/img/androidstudio.webp'

const itemData = [
    {
      img: travlr,
      title: 'Travlr-Getaway',
      author: 'MongoDB, Express, Angular JS, NodeJS, Handlebars',
      rows: 2,
      cols: 2,
      featured: true,
      link: 'https://github.com/robhardial/Travlr-Getaway'
    },
    {
      img: grazi,
      title: 'Grazioso Salvare',
      author: 'MongoDB, CRUD,Python, Full Stack Application',
      link: 'https://github.com/robhardial/CS340-Grazioso-Salvare'
    },
    {
      img: android,
      title: 'Inventory Application',
      author: 'Android Studios, Java/Kotlin, OOP',
      link: 'https://github.com/robhardial/CS360-InventoryApplication'
    },
    {
      img: cloud,
      title: 'Cloud Migration',
      author: 'AWS S3, AWS API Gateway, AWS Lambda',
      link: 'https://github.com/robhardial/Full-Stack-Cloud-II'
    },
  ];

export const Projects = () => {

    return (
        <section className="projects" id="projects">
            <Container>
                <Row className="project-title">
                    <Col>
                    <h2>Projects</h2>
                    </Col>
                </Row>
                <Row>
                    <ImageList sx={{ width: 1400, height: 700 }}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                            <img
                                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=248&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={item.title}
                                subtitle={item.author}
                                actionIcon={
                                  <a
                                  href={item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: 'none' }}
                                >
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.title}`}
                                >
                                    <InfoIcon />
                                </IconButton>
                                </a>
                                }
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Row>
            </Container>
        </section>
    )
}