import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import reacticon from "../assets/img/reacticon.png";
import wallpaper from '../assets/img/wallpaper.png';
import nodeicon from '../assets/img/nodeicon.png';
import springicon from '../assets/img/springicon.png';
import sqlicon from '../assets/img/sqlicon.png';
import awsicon from '../assets/img/awsicon.png';
import mongoicon from '../assets/img/mongoicon.png';
import dockericon from '../assets/img/dockericon.png';


export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    
    return(
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                    <div className="skill-bx">
                        <h2 className="skill-title">
                            Skills
                        </h2>
                        <p>
                            My Skill Set Includes:
                        </p>
                        <Carousel responsive={responsive} infinite={true} className="skill-slider">
                            <div className="item">
                                <img src={nodeicon} alt="Image" />
                                <h5>Node.js</h5>
                            </div>
                            <div className="item">
                                <img src={reacticon} alt="Image" />
                                <h5>React</h5>
                            </div>
                            <div className="item">
                                <img src={springicon} alt="Image" />
                                <h5>Spring Boot</h5>
                            </div>
                            <div className="item">
                                <img src={sqlicon} alt="Image" />
                                <h5>SQL</h5>
                            </div>
                            <div className="item">
                                <img src={mongoicon} alt="Image" />
                                <h5>MongoDB</h5>
                            </div>
                            <div className="item">
                                <img src={dockericon} alt="Image" />
                                <h5>Docker</h5>
                            </div>
                            <div className="item">
                                <img src={awsicon} alt="Image" />
                                <h5>AWS</h5>
                            </div>
                        </Carousel>
                    </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={wallpaper} />
        </section>
    )
}